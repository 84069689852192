/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */

import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { SubCategoryProductsQuery } from 'Query/SubCategoryProducts.query';
import { fetchQuery } from 'SourceUtil/Request/Query';
import { ReactElement } from 'Type/Common.type';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CategoryDetailsComponentProps } from './CategoryDetails.type';
import Category_empty from '../../style/icons/Category_empty.png';
import './CategoryDetails.style';
// @ts-ignore
import Slider from "react-slick";
import Link from 'SourceComponent/Link';

/**
 * Category details
 * @class CategoryDetails
 * @namespace TrouperPwa/Component/CategoryDetails/Component
 */

type Category = {
    id: string;
    name: string;
    product_count: number;
    level: number;
    image?: string;
    short_desc?: string;
    promo_msg?: string;
    url_path?:string;
    children: Array<{
        name: string;
        image?: string;
        short_desc?: string;
        promo_msg?: string;
        url_path?: string;
        url_key?: string;
        product_count?: number;
        level?: number;
        hide_pet_type?: any;
    }>;
};


interface CategoryDetailsComponentState {
    categoryData: Category[] | null;
}



export class CategoryDetailsComponent extends PureComponent<CategoryDetailsComponentProps> {
    static defaultProps: Partial<CategoryDetailsComponentProps> = {
        isCurrentCategoryLoaded: true,
    };

    state: CategoryDetailsComponentState = {
        categoryData: null,
    };

    renderSubcategoryFields(): ReactElement {
        const { categoryData } = this.state;
    
        // Slider settings
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            arrows: true,
            responsive: [
                { breakpoint: 1025, settings: { slidesToShow: 3, slidesToScroll: 1, centerPadding: '0' } },
                { breakpoint: 768, settings: { infinite: true, slidesToShow: 2, slidesToScroll: 1, centerMode: true, centerPadding: '55px', arrows: false, dots: true } }
            ]
        };

        if (!categoryData?.length) {
            return null;
        }

        return (
            <div className='Plp_slider'>
            <Slider {...settings}>
                {categoryData?.map((subcategory, index) => (
                    <Link to={subcategory?.url_path || '#'} className="subcategory-link">
                    <div key={index} className="carousel-slide">
                        <div className="slide-img-wrap">
                            {/* Render subcategory image with fallback */}
                            <img
                                src={subcategory?.image}
                                alt={subcategory?.name}
                                // ratio={ImageRatio.IMG_CUSTOM}
                            />
                        </div>

                        <div className="slide-data">
                            {/* Render subcategory name */}
                            <p className="slide-text">{subcategory?.name}</p>
        
                            {/* Render short description */}
                            {subcategory?.short_desc && (
                                <div
                                    className="slide-description"
                                    dangerouslySetInnerHTML={{ __html: subcategory?.short_desc }}
                                />
                            )}
        
                            {/* Render promo message */}
                            <div className="promo-message">{subcategory?.promo_msg && (
                                <p
                                    dangerouslySetInnerHTML={{ __html: subcategory?.promo_msg }}
                                />
                            )}</div>
                        </div>
                    </div>
                    </Link>
                ))}
            </Slider>
            </div>
        );
    }
    
    
    

    renderCategoryText(): ReactElement {
        const {
            category: { name },
            isCurrentCategoryLoaded,
            // @ts-ignore
            category: { short_desc },
        } = this.props;
        
        const {
            actionName: {
                name: preloadName,
            },
            isPrefetchValueUsed,
        } = window;

        const categoryName = isPrefetchValueUsed ? preloadName : name;

        if (isCurrentCategoryLoaded || isPrefetchValueUsed) {
            return (
                <>
                <TextPlaceholder content={ categoryName } />
                {short_desc && (
                    <div
                        className="Category_short_desc"
                        dangerouslySetInnerHTML={{ __html: short_desc }}
                    />
                )}
                </>
            );
        }

        return (
            <TextPlaceholder />
        );
    }

    renderCategoryName(): ReactElement {
        const {
            category: { name, id },
        } = this.props;
        const {
            isPrefetchValueUsed,
        } = window;

        if (!id && !name && !isPrefetchValueUsed) {
            return null;
        }

        return (
            <h1 block="CategoryDetails" elem="Heading">
                { this.renderCategoryText() }
            </h1>
        );
    }

    componentDidMount() {
        const {
            category: { id },
        } = this.props;
        if(id){
            this.fetchsubcategoryFieldsData(id && id);
        }
    }

    componentDidUpdate(prevProps:any) {
        const { category: { id } } = this.props;
        if (id !== prevProps.category.id) {
            this.fetchsubcategoryFieldsData(id && id);
        }
    }

    async fetchsubcategoryFieldsData(userId: any): Promise<void> {
        try {
            const subcategoryFieldsQuery: any = new SubCategoryProductsQuery().categoryList(userId);
            const subcategoryFieldsResponse: any = await fetchQuery(subcategoryFieldsQuery);
        
            // Access the categoryList array from the response
            const categories = subcategoryFieldsResponse?.categoryList[0].children;
            if (categories) {
                // Update state with fetched categories data
                this.setState({ categoryData: categories });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    

        renderCategoryDescription(): ReactElement {
        const {
            category: { description, id },
            isCurrentCategoryLoaded,
        } = this.props;
        const { isPrefetchValueUsed, actionName: { name: preloadDescription } } = window;

        if (isPrefetchValueUsed && !isCurrentCategoryLoaded) {
            if (preloadDescription) {
                return <Html content={ preloadDescription } />;
            }

            return null;
        }

        if ((!id || !isCurrentCategoryLoaded) && !preloadDescription) {
            return this.renderCategoryDescriptionPlaceholder();
        }

        if (!description) {
            return null;
        }

        return <Html content={ description } />;
    }

    renderCategoryDescriptionPlaceholder(): ReactElement {
        return (
            <h1>
                <TextPlaceholder length={ TextPlaceHolderLength.MEDIUM } />
            </h1>
        );
    }

    renderCategoryImagePlaceholder(): ReactElement {
        return (
            <Image
              mix={ { block: 'CategoryDetails', elem: 'Picture' } }
              ratio={ ImageRatio.IMG_CUSTOM }
              isPlaceholder
            />
        );
    }

    renderCategoryImage(): ReactElement {
        const {
            category: { image, id },
            isCurrentCategoryLoaded,
        } = this.props;

        if (!image) {
            return null;
        }

        if (!id || !isCurrentCategoryLoaded) {
            return this.renderCategoryImagePlaceholder();
        }

        return (
            <Image
              mix={ { block: 'CategoryDetails', elem: 'Picture' } }
              src={ image || '' }
              ratio={ ImageRatio.IMG_CUSTOM }
            />
        );
    }

    render(): ReactElement {
        const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
        const urlContentData = currentUrl.split('/').pop();

        if (urlContentData === "flash-sale" || urlContentData === "new-arrivals" ||  urlContentData === "offers") {
            return (
                <article block="CategoryDetails">
                    <div block="CategoryDetails" elem="Description">
                        { this.renderCategoryDescription() }
                    </div>
                </article>
            );
        }

        return (
            <article block="CategoryDetails">
                <div block="CategoryDetails" elem="Description">
                    { this.renderCategoryName() }
                    { this.renderSubcategoryFields() }
                    { this.renderCategoryDescription() }
                </div>
            </article>
        );
    }
}

export default CategoryDetailsComponent;
