import { Mutation } from '@tilework/opus';

import { Field } from 'Util/Query';

/**
 * @namespace TrouperPwa/Query/SearchHeader/Query */
export class SearchHeaderQuery {
    getRecentSearches(userId: number) {
        return new Field('getRecentSearches')
            .addArgument('userId', 'Int!', userId)
            .addFieldList(['id', 'term']);
    }

    deleteRecentSearch(searchId: number) {
        return new Field('deleteRecentSearch')
            .addArgument('searchId', 'Int!', searchId);
    }

    deleteRecentSearchKeywords(
        ids: number[],
    ): Mutation<'deleteRecentSearchKeywords', {
            success: boolean;
            message: string;
        }> {
        return new Mutation<'deleteRecentSearchKeywords', {
            success: boolean;
            message: string;
        }>('deleteRecentSearchKeywords')
            .addArgument('ids', '[Int!]!', ids)
            .addField('success')
            .addField('message');
    }

    getStoreConfig() {
        return new Field('storeConfig').addFieldList([
            'popular_search',
            'min_search_query_length',
        ]);
    }

    getSearchPlaceholder() {
        return new Field('getSearchPlaceholder');
    }

    getCombinedQuery(userId: number) {
        return new Field('query')
            .addField(this.getRecentSearches(userId))
            .addField(this.getStoreConfig())
            .addField(this.getSearchPlaceholder());
    }

    saveRecentSearch(term: string, environment = 'web') {
        return new Field('saveRecentSearch')
            .addArgument('searchInput', 'SaveRecentSearchInput!', { term, environment });
    }
}

export default SearchHeaderQuery;
