/* eslint-disable */

// import { Field } from 'Util/Query';
import { Field } from '@scandipwa/scandipwa/src/util/Query/index';

/** @namespace TrouperPwa/Query/AllBrands/Query */
export class AllBrandsQuery {
    ambrandlist(): any {
        return new Field('ambrandlist')
            .addFieldList([
                new Field('items')
                    .addFieldList([
                        'brandId',
                        'label',
                        'url',
                        'img',
                        'description',
                        'short_description',
                        'cnt',
                        'alt',
                        'is_featured',
                        'meta_title',
                        'meta_description',
                        'meta_keywords',
                        'top_cms_block_id',
                        'bottom_cms_block_id',
                        'letter',
                    ]),
            ]);
    }
}

/** @namespace TrouperPwa/Query/AllBrands/Query */
export class GetBrandList {
    // Method to build the query dynamically with the dynamic `urlKey`
    brandById(urlKey: string): any {
        // Create the query for the field and add the dynamic argument
        return new Field('brandById')
            .addArgument('urlKey', 'String', urlKey) // Pass the dynamic URL key here
            .addFieldList([
                'brandId',
                'label',
                'image',
                'mobile_image',
                'description',
                'short_description',
                'alt',
                'is_featured',
                'meta_title',
                'meta_description',
                'meta_keywords',
                'top_cms_block_id',
                'bottom_cms_block_id',
            ]);
    }
}

export const AllBrandsInstance = new AllBrandsQuery();
export const AnotherQueryInstance = new GetBrandList();
export default { AllBrandsInstance, AnotherQueryInstance };
/* eslint-disable */
